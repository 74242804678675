import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Qutoes from "../Qutoes";
import {
  dummy,
  SeurityInstallion,
  plan,
  planDuration,
  planCost,
  ElectricianIncentiveZone,
  typeOFClient,
  user_roles,
  orderTyepes,
} from "../Data";
import { useSelector } from "react-redux";
import { getFilledDetail } from "../Function/AxiosControl";
import SelectCityInput from "../city/SelectCityInput";
import { capex, getSlots, leadCreatedOrNot, newClientRegister } from "./Api";
import { notify, notifyFailed } from "../Common/Notification";
import { recursiveAmountCalculate } from "../Common/applianceDifference";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import AlertSuccessModel from "../Common/AlertSuccessModel";

export default function NewClientRegister({ dataEdit, Leadpage, styleE = "" }) {
  let teleCallers = useSelector((state) => state.sar.data.user?.first_name);
  let userName = useSelector((state) => state.salesUser);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [getData, setGetData] = useState([]);
  let [stateN, setStateN] = useState(dataEdit?.city);
  let [planD, setPlanD] = useState(dataEdit?.planFrequency);
  let [qutoBtnText, setQutoBtnText] = useState("Download");
  let [open, setOpen] = useState(false);
  let [selectedZone, setSelectedZone] = useState("");
  let [alreadyRegister, setAlreadyRegister] = useState(false);
  let [typeOfClient, setTypeOfClient] = useState(typeOFClient[0].value);
  const [leadNotFound, setLeadNotFound] = useState("");
  let [planN, setPlanN] = useState(1);
  let [planNa, setPlanNa] = useState("1 Room");
  let [userData, setUserData] = useState({});
  let [check, setCheck] = useState(false);
  let [paymentLink, setPaymentLink] = useState("");
  const [text, setText] = useState("");
  let [whatappNumber, setWhatappNumber] = useState();
  let [teleCallerSecondName, setTeleCallerSecondName] = useState("");
  let [teleCallerFirstName, setTeleCallerFirstName] = useState(teleCallers);
  let [slot, setSlot] = useState([]);
  const navigate = useNavigate();
  let [install, setInsall] = useState({
    security: 400,
    Installion: 1000,
    quantity: 4,
  });
  useEffect(() => {
    if (selectedZone === "") {
      setSlot([]);
      return;
    }
    getSlots(selectedZone)
      .then(function (res) {
        setSlot(res);
      })
      .catch(function (err) {
        setSlot([]);
      });
  }, [selectedZone]);

  useEffect(() => {
    if (planNa === "Custom") {
      setPlanN(0);
      setPlanNa("Custom");
    } else if (planNa === "1 Room") {
      setPlanN(1);
      setPlanNa("1 Room");
    } else if (planNa === "1 BHK") {
      setPlanN(2);
      setPlanNa("1 BHK");
    } else if (planNa === "2 BHK") {
      setPlanN(3);
      setPlanNa("2 BHK");
    } else if (planNa === "3 BHK") {
      setPlanN(4);
      setPlanNa("3 BHK");
    } else if (planNa === "4 BHK") {
      setPlanN(5);
      setPlanNa("4 BHK");
    } else if (planNa === "5 BHK") {
      setPlanN(6);
      setPlanNa("5 BHK");
    } else if (planNa === "6 BHK") {
      setPlanN(7);
      setPlanNa("6 BHK");
    }
    if (getData.length === 0) return;
    let quantity =
      getData.quantity !== undefined
        ? getData.quantity
        : Number(getData.totalAppliances);
    let Installion;
    if (quantity > 10) {
      let cal = quantity - 10;
      Installion = 1000 + cal * 50;
    } else {
      Installion = 1000;
    }
    setInsall({
      security: quantity === 0 ? 400 : quantity * 100,
      Installion: Installion,
      quantity: quantity === 0 ? 4 : quantity,
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let userData2 = {
      first_name: data.get("fname").trim(),
      last_name: data.get("lname").trim(),
      phone: data.get("phoneNumber"),
      city: data.get("city"),
      address: data.get("address"),
      planType:
        typeOfClient === typeOFClient[2].value ? "" : data.get("planDuration"),
      plan:
        typeOfClient === typeOFClient[2].value ? "custom" : data.get("plan"),
      origin: data.get("source"),
      leadQuality: data.get("lead_quality"),
      email: data.get("email").trim(),
      zip: data.get("zipcode"),
      comments: data.get("comments"),
      security:
        typeOfClient === typeOFClient[2].value ? 0 : data.get("security"),
      installion:
        typeOfClient === typeOFClient[2].value ? 0 : data.get("install"),
      teleCallerId:
        data.get("teleCallerFirst") === null
          ? teleCallers
          : data.get("teleCallerFirst"),
      refferal: data.get("refferal"),
      partialPayment: data.get("Partial"),
      teleCallerSecond: data.get("teleCallerSecond"),
      quantityAppliances:
        typeOfClient === typeOFClient[2].value ? 0 : data.get("appliance"),
      partialAmount: data.get("partial"),
      paymentMode: data.get("paymentMode"),
      reference_id: data.get("referenceId"),
      incentiveZone: data.get("incentiveZone"),
      costPerAppliances: data.get("costPerAppliances"),
      InstallationDate: data.get("InstallationDate"),
      discount: Number(data.get("discount")),
    };

    setWhatappNumber(userData2.phone);
    let seq =
      typeOfClient === typeOFClient[1].value ? 0 : Number(userData2.security);
    let ins = Number(userData2.installion);
    let qu = Number(userData2.quantityAppliances);
    let planDurations = userData2.planType;
    if (userData2.discount > ins + seq) {
      notifyFailed("Discount can't be Greater than Installation + Security");
      setQutoBtnText("Download");
      return;
    }
    let addon = 0;

    if (planN === 0) {
      qu = Number(userData2.quantityAppliances);
    } else {
      qu = install.quantity;
    }
    if (typeOFClient[2].value === typeOfClient) {
      qu = 0;
    }
    let fee = 0;
    if (
      typeOfClient === typeOFClient[0].value ||
      typeOfClient === typeOFClient[3].value
    ) {
      fee = await recursiveAmountCalculate(planDurations, qu);
    } else {
      fee = qu * Number(userData2.costPerAppliances);
    }

    let total = ((fee + ins) * 1.18 + seq).toFixed(2);

    setCheck(!check);
    let tax = ((fee + ins) * 0.18).toFixed(2);
    setUserData({ ...userData2, totalAppliances: qu });
    userData2 = {
      ...userData2,
      totalAppliances: qu,
      orignalTotalAmount: Math.ceil(total),
      totalAmount: Math.ceil(total),
      orignalRecursiveAmount: fee,
      recursiveAmount: fee,
      tax: tax,
      costAppliances: Number(userData.costPerAppliances),
    };
    typeOfClient === typeOFClient[1].value
      ? capex(
          userData2,
          stateN,
          setText,
          setPaymentLink,
          setQutoBtnText,
          setWhatappNumber,
          { planName: "OneTime" },
          setOpen
        )
      : newClientRegister(
          userData2,
          setQutoBtnText,
          planN,
          stateN,
          userRole,
          teleCallers,
          dummy,
          setText,
          setPaymentLink,
          install,
          setOpen,
          typeOfClient
        );
  };

  useEffect(() => {
    if (planN === 0) return;
    setInsall(SeurityInstallion[planN - 1]);
  }, [planN]);

  const handlePlanDuration = (event) => {
    setPlanD(event.target.value);
  };

  const handleTeleCallerSecond = (event) => {
    setTeleCallerSecondName(event.target.value);
  };
  const handleTeleCallerFirst = (event) => {
    setTeleCallerFirstName(event.target.value);
  };
  const handlePlan = (event) => {
    setPlanNa(event.target.value);
    if (event.target.value === "Custom") {
      setPlanN(0);
    } else if (event.target.value === "1 Room") {
      setPlanN(1);
    } else if (event.target.value === "1 BHK") {
      setPlanN(2);
    } else if (event.target.value === "2 BHK") {
      setPlanN(3);
    } else if (event.target.value === "3 BHK") {
      setPlanN(4);
    } else if (event.target.value === "4 BHK") {
      setPlanN(5);
    } else if (event.target.value === "5 BHK") {
      setPlanN(6);
    } else if (event.target.value === "6 BHK") {
      setPlanN(7);
    }
  };

  const handleChangeCustom = (event) => {
    let quantitys = event;
    let sequrity = quantitys * 100;
    let Installion;
    if (quantitys > 10) {
      let cal = quantitys - 10;
      Installion = 1000 + cal * 50;
    } else {
      Installion = 1000;
    }
    setInsall({
      planName: "Custom",
      security: sequrity,
      Installion: Installion,
      quantity: quantitys,
    });
  };

  const callFilled = async (number) => {
    if (number.length < 10) return;
    let Lead = await leadCreatedOrNot(number);
    console.log(Lead, "found lead");
    setGetData(Lead);
    if (Object.keys(Lead).length === 0) {
      notifyFailed("First Register Lead");
      setLeadNotFound("notFound");
    }
  };

  return (
    <div
      style={{
        padding: styleE === user_roles.ExternalTeleCaller ? "0 4%" : "0",
        marginTop: styleE === user_roles.ExternalTeleCaller ? "4vh" : 0,
      }}
    >
      <AlertSuccessModel open={open} setOpen={setOpen} />
      <Box
        component="form"
        validate={true.toString()}
        onSubmit={handleSubmit}
        id="create-course-form"
      >
        <div className="make_quto">
          <div className="row">
            <div className="col-6">
              <label className="create-label1">
                Phone Number <span className="required_star">*</span>
              </label>
              <div class="input-group mb-1 ">
                <input
                  type="text"
                  class="form-control"
                  id="phoneNumber"
                  name="phoneNumber"
                  autoComplete="current-phoneNumber"
                  defaultValue={getData?.phone}
                  required
                  onChange={(ele) => {
                    if (!userRole.includes(user_roles.ExternalTeleCaller)) {
                      callFilled(ele.target.value);
                    } else {
                      setGetData({ fff: "fff" });
                    }
                  }}
                  placeholder="Phone Number"
                  aria-label="phoneNumber"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="col-6">
              <label className="create-label1">
                Subscription Zone <span className="required_star">*</span>
              </label>
              <div class="input-group ">
                <select
                  class="custom-select height-select"
                  required
                  id="inputGroupSelect01"
                  name="incentiveZone"
                  label="incentiveZone"
                  autoComplete="current-incentiveZone"
                  onChange={(e) =>
                    setSelectedZone(e.target.value.toUpperCase())
                  }
                >
                  <option value={""}>Select Zone</option>
                  {ElectricianIncentiveZone.map((option) => (
                    <option value={option.value}>{option.value}</option>
                  ))}
                </select>
              </div>
            </div>
            {!userRole.includes(user_roles.ExternalTeleCaller) && (
              <div className="col-6">
                <label className="create-label1">
                  Type of Client <span className="required_star">*</span>
                </label>
                <div class="input-group ">
                  <select
                    class="custom-select height-select"
                    required
                    id="inputGroupSelect01"
                    name="typeofClient"
                    label="typeofClient"
                    autoComplete="current-typeofClient"
                    onChange={(ele) => setTypeOfClient(ele.target.value)}
                  >
                    {typeOFClient.map((option) => (
                      <option value={option.value}>{option.value}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="col-6">
              <label className="create-label1">Installation Date</label>
              <div class="input-group ">
                {/* <select 
                                    className="custom-select height-select"
                                    id="inputGroupSelect01"
                                    name="InstallationDate"
                                    label="InstallationDate"
                                    autoComplete="current-InstallationDate"
                                >
                                    <option value={""}>Select Slot</option>
                                    {slot.map((option) => (
                                        <option value={option.date}>{moment(option.date).format("Do MMM, dddd")}{"  - "}{option.count===0?"":`${option.count} Slot`}{" "}{option.slotStatus.toUpperCase()}</option>
                                    ))}
                                </select> */}
                <input
                  className="custom-select height-select"
                  type="datetime-local"
                  id="inputGroupSelect01"
                  name="InstallationDate"
                  label="InstallationDate"
                  autoComplete="current-InstallationDate"
                />
              </div>
            </div>
          </div>
        </div>
        {Object.keys(getData).length !== 0 ? (
          <>
            <div className="make_quto">
              <div className="row">
                <div className="col-6">
                  <label className="create-label1">
                    First Name <span className="required_star">*</span>
                  </label>
                  <div class="input-group mb-1">
                    <input
                      type="text"
                      class="form-control"
                      id="fname"
                      name="fname"
                      autoComplete="fname"
                      defaultValue={getData?.first_name}
                      required
                      placeholder="First Name"
                      aria-label="fname"
                      aria-describedby="basic-addon1"
                      onFocus={(e) => {
                        console.log(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="create-label1">Last Name</label>
                  <div class="input-group mb-1">
                    <input
                      type="text"
                      class="form-control"
                      id="lname"
                      name="lname"
                      defaultValue={getData?.last_name}
                      autoComplete="current-lname"
                      placeholder="Last Name"
                      aria-label="lname"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="create-label1">
                    Email <span className="required_star">*</span>
                  </label>
                  <div class="input-group ">
                    <input
                      type="email"
                      class="form-control"
                      required
                      defaultValue={getData?.email}
                      id="email"
                      name="email"
                      autoComplete="current-email"
                      placeholder="Email"
                      aria-label="email"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="create-label1">
                    City ({getData?.city})
                    <span className="required_star">*</span>
                  </label>
                  <div class="input-group ">
                    <SelectCityInput value={stateN} setValue={setStateN} />
                  </div>
                </div>
              </div>
            </div>
            {![typeOFClient[2].value, typeOFClient[4].value].includes(
              typeOfClient
            ) && (
              <>
                <div className="make_quto">
                  <div className="row">
                    <div className="col-6">
                      <label className="create-label1">
                        Plan Name <span className="required_star">*</span>
                      </label>
                      <div class="input-group ">
                        <select
                          class="custom-select height-select"
                          required
                          id="inputGroupSelect01"
                          onChange={handlePlan}
                          defaultValue="choose one"
                          name="plan"
                          label="plan"
                          autoComplete="current-plan"
                          value={planNa}
                        >
                          {plan.map((option) => (
                            <option value={option.text}>{option.text}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {(typeOfClient === typeOFClient[0].value ||
                      typeOfClient === typeOFClient[3].value) && (
                      <div className="col-6">
                        <label className="create-label1">
                          Plan Duration <span className="required_star">*</span>
                        </label>
                        <div className="input-group ">
                          <select
                            className="custom-select height-select"
                            required
                            id="inputGroupSelect01"
                            onChange={handlePlanDuration}
                            name="planDuration"
                            label="planDuration"
                            autoComplete="current-planDuration"
                            defaultValue={getData?.frequency || planD}
                          >
                            {planDuration.map((option) => (
                              <option value={option.value}>
                                {option.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>

                  {planN === 0 ? (
                    <div className="row">
                      <div className="col-12">
                        <label className="create-label1">
                          Total Appliances
                        </label>
                        <div class="input-group ">
                          <input
                            type="text"
                            defaultValue={
                              getData.quantity == 0 ? 4 : getData.quantity
                            }
                            class="form-control"
                            id="appliance"
                            name="appliance"
                            autoComplete="current-appliance"
                            placeholder="Total appliances"
                            aria-label="appliance"
                            aria-describedby="basic-addon1"
                            onChange={(e) => {
                              handleChangeCustom(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="make_quto">
                  <div className="row">
                    {typeOfClient === typeOFClient[0].value ||
                    typeOfClient === typeOFClient[3].value ? (
                      <div className="col-6">
                        <label className="create-label1">Security</label>
                        <div class="input-group mb-1">
                          <input
                            type="number"
                            class="form-control"
                            value={install.security}
                            id="security"
                            name="security"
                            autoComplete="current-security"
                            onChange={(e) => {
                              setInsall({
                                ...install,
                                security: e.target.value,
                              });
                            }}
                            placeholder="Security"
                            aria-label="security"
                            aria-describedby="basic-addon1"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-6">
                        <label className="create-label1">
                          Cost Per Appliances
                        </label>
                        <div class="input-group mb-1">
                          <input
                            type="number"
                            class="form-control"
                            id="costPerAppliances"
                            name="costPerAppliances"
                            autoComplete="current-costPerAppliances"
                            placeholder="Security"
                            aria-label="security"
                            aria-describedby="basic-addon1"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-6">
                      <label className="create-label1">Installation</label>
                      <div class="input-group mb-1">
                        <input
                          type="number"
                          class="form-control"
                          value={install.Installion}
                          id="install"
                          name="install"
                          autoComplete="current-install"
                          onChange={(e) => {
                            setInsall({
                              ...install,
                              Installion: e.target.value,
                            });
                          }}
                          placeholder="Installation"
                          aria-label="install"
                          aria-describedby="basic-addon1"
                          onWheel={(event) => event.currentTarget.blur()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <label className="create-label1">Partial Amount</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="partial"
                          name="partial"
                          autoComplete="current-partial"
                          placeholder="Partial Amount"
                          aria-label="partial"
                          aria-describedby="basic-addon1"
                          defaultValue={100}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <label className="create-label1">Discount</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="discount"
                          name="discount"
                          autoComplete="current-discount"
                          placeholder="Discount"
                          aria-label="discount"
                          aria-describedby="basic-addon1"
                          defaultValue={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {userRole.includes("External Telecaller") ||
            userRole.includes(user_roles.ExternalTeleCaller) ? null : (
              <div className="make_quto">
                {typeOfClient === typeOFClient[2].value && (
                  <div className="row">
                    <div className="col-12">
                      <label className="create-label1">Partial Amount</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="partial"
                          name="partial"
                          autoComplete="current-partial"
                          placeholder="Partial Amount"
                          aria-label="partial"
                          aria-describedby="basic-addon1"
                          defaultValue={100}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-6">
                    <label className="create-label1">
                      First Telecaller Name
                    </label>
                    <div class="input-group">
                      <select
                        class="custom-select height-select"
                        id="inputGroupSelect01"
                        onChange={handleTeleCallerFirst}
                        defaultValue="choose one"
                        name="teleCallerFirst"
                        label="teleCallerFirst"
                        autoComplete="current-teleCallerFirst"
                        value={teleCallerFirstName}
                      >
                        {userName?.map((option) => (
                          <option value={option.first_name}>
                            {option.first_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="create-label1">
                      Second Telecaller Name
                    </label>
                    <div class="input-group">
                      <select
                        class="custom-select height-select"
                        id="inputGroupSelect01"
                        onChange={handleTeleCallerSecond}
                        defaultValue="choose one"
                        name="teleCallerSecond"
                        label="teleCallerSecond"
                        autoComplete="current-teleCallerSecond"
                        value={teleCallerSecondName}
                      >
                        <option value="empty">
                          choose if Second Telecaller
                        </option>
                        {userName?.map((option) => {
                          if (
                            option.roles !== undefined &&
                            option.roles.includes(user_roles.LeadHandler)
                          ) {
                            return (
                              <option value={option.first_name}>
                                {option.first_name}
                              </option>
                            );
                          }
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div style={{ marginLeft: "1%" }}>
              <div className="btn-create-container">
                <div className="btn-create">
                  {stateN !== undefined &&
                  stateN !== "" &&
                  userName !== undefined &&
                  stateN !== "" ? (
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        pointerEvents: `${
                          qutoBtnText === "Processing..." ? "none" : ""
                        }`,
                        backgroundColor: `${
                          qutoBtnText === "Processing..."
                            ? "#cccccc"
                            : "#2552B2"
                        }`,
                      }}
                      onClick={() => {
                        setText("");
                      }}
                    >
                      {qutoBtnText}
                    </Button>
                  ) : null}
                </div>

                {text !== "" ? (
                  <>
                    <div className="btn-create">
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigator.clipboard.writeText(paymentLink)
                        }
                      >
                        Copy
                      </Button>
                    </div>
                    <div className="btn-create">
                      <a
                        href={`http://wa.me/${whatappNumber}?text=${text}`}
                        target="_blank"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/icon/WhatsApp_logo.svg"
                          }
                          style={{ height: "47px" }}
                        />
                      </a>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </Box>
      {userName == undefined ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{ fontSize: "15px", marginTop: "5%" }}
        >
          LogOut your account from all devices
        </Alert>
      ) : null}
      {leadNotFound !== "" ? (
        <Alert
          variant="filled"
          severity="error"
          sx={{ fontSize: "15px", marginTop: "5%" }}
        >
          First Register Lead
          <Button
            onClick={() => {
              Leadpage();
            }}
          >
            Click here
          </Button>
        </Alert>
      ) : null}

      <Qutoes />
    </div>
  );
}
