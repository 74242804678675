import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SearchResult.css';
import './SearchOnSingle.css';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import {
  department,
  orderTyepes,
  user_roles,
  takePaymentOnZones,
  typeOFClient,
} from '../Data';
import CollectExtraPartialPayment from './CollectExtraPartialPayment';
import RefundDetails from './RefundDetails';
import Deleteanddrop from './Deleteanddrop';
import ChangeInstallationDate from './ChangeInstallationDate';
import { subHistory } from './SubscriptionHistory';
import ShowDevices from '../OnCallGrivance/ShowDevices';
import ManualSwitchBlock from '../SearchClient/ManualSwitchBlock';
import HexOverDuePaymentLink from '../SearchClient/HexOverDuePaymentLink';
import SalesSetUninstallation from '../SearchClient/SalesSetUninstallation';
import ClientDetailsUpdate from '../SearchClient/ClientDetailsUpdate';
import RegiterGrivance from '../SearchClient/RegiterGrivance';
import ChangeHyperLink from './ChangeHyperLink';
import ReAttatchMandate from '../Mandate/ReAttatchMandate';
import UpgradeDowngradeUi from '../SearchClient/UpgradeDowngradeUi';
import PartialUpgradeDownGrade from '../SearchClient/PartialUpgradeDownGrade';
import CollectRazorpayRemaning from './CollectRazorpayRemaning';
import PlanUpdate from './PlanUpdate';
import CombineSubscription from '../SearchClient/CombineSubscription';
import HouseAccess from '../SmallComponent/HouseAccess';
import HouseDeviceActivateDeactivate from '../SmallComponent/HouseDeviceActivateDeactivate';
import UpdateHouseIdDevices from '../SmallComponent/UpdateHouseIdDevices';
import ReteriveClient from '../DropClient/ReteriveClient';
import ClientJoureny from '../SearchClient/ClientJoureny';
import InvoiceDetails from '../SearchClient/InvoiceDetails';
import ChangeBothTeleCaller from '../SearchClient/ChangeBothTeleCaller';
import CardInvoice from '../SearchClient/CardInvoice';
import ClientSubscriptionChangeHistories from '../SearchClient/ClientSubscriptionChangeHistories';
import GrivanceHistory from '../OnCallGrivance/GrivanceHistory';
import GetOverduePaymentLinkRzp from '../SearchClient/GetOverduePaymentLinkRzp';
import ZohoWebHook from '../test/ZohoWebHook';
import PartialTest from '../test/PartialTest';
import PartialtoFullZoho from '../test/PartialtoFullZoho';
import PartialtoFullRzp from '../test/PartialtoFullRzp';
import DirectRzpFull from '../test/DirectRzpFull';
import SendOverDueLinkWhatapp from '../SearchClient/SendOverDueLinkWhatapp';
import PostPayment from '../SearchClient/PostPayment';
import { useSelector } from 'react-redux';
import ReAttachMandatePaymentLink from '../SearchClient/ReAttachMandatePaymentLink';
import { Stack } from '@mui/material';
import { calculatePartialAmount } from './AxiosControl';
import SubscrionStageChange from '../SearchClient/SubscrionStageChange';
import UninstallDoneBtn from '../SpecificElectricianUpGradeDownGrade.js/UninstallDoneBtn';
import ClientHouseWiseRetention from '../Retention/ClientHouseWiseRetention';
import UpdateUpsellProduct from '../SearchClient/UpdateUpsellProduct';
import ReInstallation from '../SearchClient/ReInstallation';
import RevokeReInstallation from '../SearchClient/RevokeReInstallation';
import LastTenActionOnGrievence from '../OnCallGrivance/LastTenActionOnGrievence';
import CreditNote from '../SearchClient/CreditNote';
import CreditNoteDetail from '../SearchClient/CreditNoteDetail';
import ConvertSiteVisitToOther from '../SiteVisit/ConvertSiteVisitToOther';
import FittingStarted from '../SpecificElectricianUpGradeDownGrade.js/FittingStarted';
import { notifyFailed } from '../Common/Notification';
import NotShowingOnGrievencePageHeena from './NotShowingOnGrievencePageHeena';
import LedgerForGeneratedHex from './LedgerForGeneratedHex';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function SearchOnSingle({
  e,
  loginName,
  setReload,
  reload,
  completeData,
}) {
  const [expandeing, setExpanding] = React.useState(
    completeData.length === 1 ? true : false
  );
  let [invoice, setInvoice] = useState([]);
  let [requestPop, setRequestPop] = useState(false);
  let [meetingPop, setMeetingPop] = useState(false);
  let [meetingData, setMeetingData] = useState({});
  let [requestData, setRequestData] = useState({});
  let [requestType, setRequestType] = useState('');
  let [pop, setPop] = useState(false);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let employeeId = useSelector((state) => state.sar.data?.user?.employeeId);
  let [popPartial, setPopPartial] = useState(false);
  let [overdueAmount, setOverdueAmount] = useState('0.00');
  let [partialAmounts, setPartialAmounts] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);

  const handleExpandClick = () => {
    setExpanding(!expandeing);
  };

  const notify = (text) => toast(text);
  let meetDate = useRef(null);
  let meetingComment = useRef(null);
  let meetingStatu = useRef(null);

  const requests = (data) => {
    axios
      .post(`${serverUrl.u}/api/zoho/createRequest`, {
        enteringPerson: loginName,
        priorityNumber: data.priority,
        generatedHex: data.generatedHex,
        type: requestType,
        comments: `${data.comment} - ${moment(new Date()).format(
          'DD-MM-YYYY'
        )}`,
        department: data.dep,
      })
      .then(function (res) {
        if (res.data.success === true) {
          notify('Request Sucessfully Send');
          subHistory('Ticket Rasied', data, 'Ticket Rasied', requestType);
          setRequestPop(!requestPop);
        } else {
          notify(res.data.message);
        }
      })
      .catch(function (err) {
        console.log(err);
        notify(err.data.message);
      });
  };
  const RequesthandleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let userData = {
      generatedHex: data.get('hex'),
      comment: data.get('comment'),
      priority: data.get('priority'),
      dep: data.get('department'),
    };
    requests(userData);
  };

  const meetingSchedule = (dateTime, comment, status) => {
    axios
      .post(`${serverUrl.u}/api/tele/updateMeetingSchedule`, {
        generatedHex: meetingData.generatedHex,
        fieldVisite: true,
        fieldVisiteDateTime: dateTime,
        meetingComment: comment,
        meetingStatus: status,
        name: e.name,
        teleCallerId: e.teleCallerId,
        planFrequency: e.planFrequency,
        totalAppliances: e.totalAppliances,
      })
      .then(function (res) {
        if (res.status === 200) {
          notify('Meeting Set Sucessfully');
          setMeetingPop(false);
        } else {
          notify('error occured');
        }
      })
      .catch(function (err) {
        console.log(err);
        notify('error occured');
      });
  };

  useEffect(() => {
    let Overdue = 0;
    const invoiceDetails = async () => {
      await axios
        .post(`${serverUrl.u}/api/tele/getAllInvoicesDetails`, {
          generatedHex: e.generatedHex,
        })
        .then(function (res) {
          if (res.data.success === true) {
            for (let i of res.data.data) {
              if (i.status === 'overdue' && i.masterCancel === false) {
                Overdue += i.actualTotalAmount;
              }
            }
            setOverdueAmount(Overdue.toFixed(2));
          } else {
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    };
    invoiceDetails();
  }, []);

  const Email = () => {
    if (e.hasOwnProperty('email')) {
      if (e.email.length <= 22) {
        return e.email;
      } else {
        let email = e.email.split('@');
        return email[0] + ' ' + '@' + email[1];
      }
    } else {
      return 'E-Mail Not Registered';
    }
  };
  let len = e.AssignedElectritianList.length;
  let lastStatusOfSubscription =
    e?.changesInSubscription[e.changesInSubscription.length - 1]
      ?.changeInSubscription;

  const CheckChangeInSubscription = () => {
    if (lastStatusOfSubscription === 'Frequency') {
      return true;
    } else if (lastStatusOfSubscription === 'reactivate') {
      return true;
    } else if (lastStatusOfSubscription === 'reatachMandate') {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Card
        sx={{
          padding: '30px',
          marginTop: '20px',
          boxShadow: 'none',
          position: 'relative',
          borderRadius: '13px',
          border: '1px solid #C6C6C6',
        }}
      >
        <div
          style={{ display: 'flex', width: '100%' }}
          onClick={() => setExpanding(true)}
        >
          <div className='mainContainerInner'>
            <div className='mainSubHeadingSub'>Name</div>
            <div className='mainHeadingSub'>{e.name}</div>
          </div>
          <div className='mainContainerInner'>
            <div className='mainSubHeadingSub'>Hex</div>
            <div className='mainHeadingSub'>
              {e.generatedHex} {e.B2BRoom_No}
            </div>
          </div>
          {(orderTyepes[0].value === e.orderType ||
            orderTyepes[4].value === e.orderType) && (
            <div className='mainContainerInner'>
              <div className='mainSubHeadingSub'>Plan Frequency</div>
              <div className='mainHeadingSub'>{e.planFrequency} </div>
            </div>
          )}
          {orderTyepes[2].value !== e.orderType && (
            <div className='mainContainerInner'>
              <div className='mainSubHeadingSub'>Appliances</div>
              <div className='mainHeadingSub'>{e.totalAppliances}</div>
            </div>
          )}
          <div className='mainContainerInner' style={{ width: '24%' }}>
            <div className='mainSubHeadingSub'>Subscription Stage</div>
            <div className='mainHeadingSub'>
              <span style={{ color: '#00057D' }}>{e.subscriptionStage}</span>{' '}
              {e.grivience === true && e.uninstallation === false ? (
                <span style={{ color: '#DC3545' }}>& Grivience</span>
              ) : (
                ''
              )}
              {e.uninstallation === true ? (
                <span style={{ color: '#DC3545' }}>& Uninstallation</span>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className='mainContainerInner' style={{ width: '17%' }}>
            <div className='mainSubHeadingSub'>Payment Status</div>
            <div
              className='mainHeadingSub'
              style={{
                color:
                  lastStatusOfSubscription === 'reactivate'
                    ? overdueAmount === '0.00'
                      ? '#008000'
                      : '#FF0000'
                    : e.reatachMandate === true
                    ? '#008000'
                    : e.partialPayment === true
                    ? '#e4e4158c'
                    : overdueAmount === '0.00'
                    ? '#008000'
                    : '#FF0000',
              }}
            >
              {lastStatusOfSubscription === 'reactivate'
                ? overdueAmount !== '0.00'
                  ? `Overdue - ₹${overdueAmount}`
                  : 'Paid'
                : e.reatachMandate === true
                ? overdueAmount !== '0.00'
                  ? `Overdue - ₹${overdueAmount}`
                  : 'Paid'
                : e.partialPayment === true
                ? e.partialAmount !== 0
                  ? 'Partial'
                  : 'Zero'
                : overdueAmount !== '0.00'
                ? `Overdue - ₹${overdueAmount}`
                : 'Paid'}
            </div>
          </div>

          <div className='mainContainerInner'>
            <div className='mainSubHeadingSub'>Subscription Status</div>
            <div
              className='mainHeadingSub'
              style={{ color: e.dropped === false ? '#008000' : '#FF0000' }}
            >
              {e.dropped === false ? 'Active' : 'Dropped'}
            </div>
          </div>
        </div>

        <CardActions
          disableSpacing
          sx={{ height: '0px', padding: '0 !important' }}
        >
          <ExpandMore
            sx={{
              height: '25px',
              padding: '0 !important',
              position: 'absolute',
              top: '33px',
              right: '10px',
            }}
            className='expandmore'
            expand={expandeing}
            onClick={() => handleExpandClick()}
            aria-expanded={expandeing}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expandeing} timeout='auto' unmountOnExit>
          <CardContent sx={{ padding: '0px !important' }}>
            <div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '15px',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                {/* {
                  orderTyepes[0].value === e.orderType && (
                    <div className="mainContainerInnerInner">
                      <div className="mainSubHeadingSub">Mandate Date</div>
                      <div className="mainHeadingSub">{moment(e.InstallationDate).format('DD-MMM-YYYY')}</div>
                    </div>)} */}
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>
                    {e.dropped === true
                      ? 'Installation Date'
                      : 'Installation Date'}
                  </div>
                  <div className='mainHeadingSub'>
                    {moment.utc(e.mandateDate).format('DD-MMM-YYYY')}
                  </div>
                </div>
                {/* <div className="mainContainerInnerInner">
                  <div className="mainSubHeadingSub">{"Installed Done Date"}</div>
                  <div className="mainHeadingSub">{moment.utc(e.installDoneDate).format('llll')}</div>
                </div> */}
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>House Name</div>
                  <div className='mainHeadingSub'>{e.houseName}</div>
                </div>
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '24%' }}
                >
                  <div className='mainSubHeadingSub'>Email</div>
                  <div className='mainHeadingSub'>{Email()}</div>
                </div>
                {e.orderType === orderTyepes[0].value && (
                  <div
                    className='mainContainerInnerInner'
                    style={{ width: '17%' }}
                  >
                    <div className='mainSubHeadingSub'>App Status</div>
                    <div
                      className='mainHeadingSub'
                      style={{
                        color: e.active === true ? '#008000' : '#FF0000',
                      }}
                    >
                      {e.active === true ? 'Active' : 'Deactive'}
                    </div>
                  </div>
                )}

                {/* <div className="mainContainerInnerInner">
                  <div className="mainSubHeadingSub">Switch Status</div>
                  <div className="mainHeadingSub">dummy</div>
                </div> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '15px',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                {partialAmounts !== 0 ? (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>
                      Calculate Partial Amount
                    </div>
                    <div className='mainHeadingSub'>{partialAmounts}</div>
                  </div>
                ) : null}
                {orderTyepes[0].value === e.orderType && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>Security</div>
                    <div className='mainHeadingSub'>{e.security}</div>
                  </div>
                )}

                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>Installation</div>
                  <div className='mainHeadingSub'>{e.installation}</div>
                </div>
                {orderTyepes[0].value === e.orderType && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>Recursive</div>
                    <div className='mainHeadingSub'>{e.recursiveAmount}</div>
                  </div>
                )}

                <div
                  className='mainContainerInnerInner'
                  style={{ width: '24%' }}
                >
                  <div className='mainSubHeadingSub'>Total</div>
                  <div className='mainHeadingSub'>
                    {e.totalAmount.toFixed(2)}
                  </div>
                </div>
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '17%' }}
                >
                  <div className='mainSubHeadingSub'>Current Electrician</div>
                  <div className='mainHeadingSub'>
                    {' '}
                    {Array.isArray(e.AssignedElectritian) ? (
                      <ul>
                        {e.AssignedElectritian &&
                          e.AssignedElectritian.map((name) => {
                            return (
                              <li>
                                {typeof name === 'object' && name !== null
                                  ? name.electricianName
                                  : name}
                              </li>
                            );
                          })}
                      </ul>
                    ) : (
                      e.AssignedElectritian
                    )}
                  </div>
                </div>
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>
                    Last Electrician Visit
                  </div>
                  <div className='mainHeadingSub'>
                    {typeof e.AssignedElectritianList[len - 1] === 'object'
                      ? e.AssignedElectritianList[len - 1].electricianName
                      : e.AssignedElectritianList[len - 1]}
                  </div>
                </div>
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>Type of Client</div>
                  <div className='mainHeadingSub'>{e.orderType}</div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '15px',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                {(orderTyepes[0].value === e.orderType ||
                  orderTyepes[4].value === e.orderType) && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>Current Security</div>
                    <div className='mainHeadingSub'>
                      {e.reatachMandate === false
                        ? e.currentSecurity
                        : e.security}
                    </div>
                  </div>
                )}
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>Current Installation</div>
                  <div className='mainHeadingSub'>
                    {e.reatachMandate === false
                      ? e.currentInstallation
                      : e.installation}
                  </div>
                </div>
                {(orderTyepes[0].value === e.orderType ||
                  orderTyepes[4].value === e.orderType) && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>Current Recursive</div>
                    <div className='mainHeadingSub'>
                      {e.reatachMandate === false
                        ? e.currentRecursive
                        : e.recursiveAmount}
                    </div>
                  </div>
                )}
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '24%' }}
                >
                  <div className='mainSubHeadingSub'>Current Total</div>
                  <div className='mainHeadingSub'>
                    {e.reatachMandate === false
                      ? e.currentTotal.toFixed(2)
                      : e.totalAmount.toFixed(2)}
                  </div>
                </div>
                {(orderTyepes[0].value === e.orderType ||
                  orderTyepes[4].value === e.orderType) && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>
                      Subscription On Mandate
                    </div>
                    <div className='mainHeadingSub'>
                      {e.subscriptionsIsOnMandate === true ? 'Yes' : 'No'}
                    </div>
                  </div>
                )}
                {(orderTyepes[0].value === e.orderType ||
                  orderTyepes[4].value === e.orderType) && (
                  <div className='mainContainerInnerInner'>
                    <div className='mainSubHeadingSub'>Old Status</div>
                    <div className='mainHeadingSub'>
                      {e.changesInSubscription.length !== 0
                        ? e.changesInSubscription[
                            e.changesInSubscription.length - 1
                          ].oldAppliances !== undefined
                          ? `${
                              e.changesInSubscription[
                                e.changesInSubscription.length - 1
                              ].statusOfSubscription
                            } - ${
                              e.changesInSubscription[
                                e.changesInSubscription.length - 1
                              ].changeInSubscription
                            } - ${
                              e.changesInSubscription[
                                e.changesInSubscription.length - 1
                              ].oldAppliances
                            }`
                          : 'No Changes'
                        : 'No Changes'}
                    </div>
                  </div>
                )}
                <div className='mainContainerInnerInner'>
                  <div className='mainSubHeadingSub'>Site Done</div>
                  <div className='mainHeadingSub'>
                    {e.siteDone ? 'Yes' : 'No'}
                  </div>
                </div>
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '24%' }}
                >
                  <div className='mainSubHeadingSub'>Discount</div>
                  <div className='mainHeadingSub'>
                    {typeof e?.discount !== 'object'
                      ? e?.discount
                      : e?.discount?.amount}
                  </div>
                </div>
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '24%' }}
                >
                  <div className='mainSubHeadingSub'>Greivance Handler</div>
                  <div className='mainHeadingSub'>{e.grivienceHandler}</div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: '15px',
                  flexWrap: 'wrap',
                  marginTop: '30px',
                }}
              >
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '50%' }}
                >
                  <div className='mainSubHeadingSub'>Address</div>
                  <div className='mainHeadingSub'>{e.address}</div>
                </div>
                <div
                  className='mainContainerInnerInner'
                  style={{ width: '50%' }}
                >
                  <div className='mainSubHeadingSub'>Address Link</div>
                  <div className='mainHeadingSub'>
                    {
                      <ChangeHyperLink
                        e={e}
                        reloadW={reload}
                        setReload={setReload}
                        notify={notify}
                      />
                    }
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  marginTop: '20px',
                }}
              >
                <Stack>
                  <div className='mainSubHeadingSub'>Client Issue</div>
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    flexWrap={'wrap'}
                    gap={1}
                  >
                    {loginName === 'Akhil' && <ZohoWebHook />}
                    {loginName === 'Akhil' && <PartialtoFullZoho />}
                    {loginName === 'Akhil' && <PartialTest />}
                    {loginName === 'Akhil' && (
                      <PartialtoFullRzp hex={e.generatedHex} />
                    )}
                    {loginName === 'Akhil' && <DirectRzpFull />}
                    {/* {loginName === "Akhil" && <UninstallDoneBtn data={e} />} */}
                    <RegiterGrivance
                      data={e}
                      reload={reload}
                      setReload={setReload}
                      notify={notify}
                    />
                    <NotShowingOnGrievencePageHeena
                      sub={e}
                      reload={reload}
                      setReload={setReload}
                    />
                    <button
                      className='btn btn-primary'
                      onClick={() => {
                        setRequestData(e);
                        setRequestType('general');
                        setRequestPop(true);
                      }}
                    >
                      Raise a Ticket
                    </button>

                    <RefundDetails e={e} notify={notify} />
                    {!e.dropped && <SalesSetUninstallation e={e} />}
                  </Stack>
                </Stack>
                <div>
                  <div className='mainSubHeadingSub'>Payments</div>
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    flexWrap={'wrap'}
                    gap={1}
                  >
                    {typeOFClient[2].value === e.orderType && (
                      <ConvertSiteVisitToOther
                        sub={e}
                        reload={reload}
                        setReload={setReload}
                      />
                    )}
                    {(orderTyepes[0].value === e.orderType ||
                      orderTyepes[2].value === e.orderType ||
                      orderTyepes[4].value === e.orderType) && (
                      <HexOverDuePaymentLink e={e} />
                    )}
                    {(orderTyepes[0].value === e.orderType ||
                      orderTyepes[2].value === e.orderType ||
                      orderTyepes[4].value === e.orderType) && (
                      <GetOverduePaymentLinkRzp e={e} notify={notify} />
                    )}
                    {e.partialPayment === false && e.dropped === false ? (
                      <ReAttatchMandate
                        data={e}
                        reload={reload}
                        setReload={setReload}
                      />
                    ) : null}
                    {e.reatachMandate === false && <PostPayment sub={e} />}

                    {e.dropped === false ? (
                      e.partialPayment === true ? (
                        <>
                          {(orderTyepes[0].value === e.orderType ||
                            orderTyepes[4].value === e.orderType) && (
                            <button
                              className='btn btn-primary'
                              onClick={() => {
                                if (e.reatachMandate === false) {
                                  setPopPartial(true);
                                } else {
                                  setPop(true);
                                }
                              }}
                            >
                              DownGrade/Upgrade
                            </button>
                          )}
                          {e.orderType === orderTyepes[2].value && (
                            <UpdateUpsellProduct
                              sub={e}
                              reload={reload}
                              setReload={setReload}
                            />
                          )}
                          <CollectExtraPartialPayment e={e} notify={notify} />
                          {true && (
                            <>
                              <CollectRazorpayRemaning e={e} />
                              {(e.orderType === orderTyepes[0].value ||
                                orderTyepes[4].value === e.orderType) && (
                                <PlanUpdate e={e} />
                              )}
                            </>
                          )}

                          {e.reatachMandate === true ? (
                            <ReAttachMandatePaymentLink sub={e} />
                          ) : null}
                        </>
                      ) : (
                        <>
                          <button
                            className='btn btn-primary'
                            onClick={() => {
                              setPop(true);
                            }}
                            disabled={
                              e.partialPayment === false &&
                              (e.InstallationDate === '' ||
                                e.InstallationDate === null)
                            }
                          >
                            DownGrade/UpGrade
                          </button>
                        </>
                      )
                    ) : null}
                    {/* {!e.dropped &&<CombineSubscription notify={notify} completeData={completeData}/>} */}
                  </Stack>
                </div>
                <div>
                  <div className='mainSubHeadingSub'>Status</div>
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    flexWrap={'wrap'}
                    gap={1}
                  >
                    <ChangeInstallationDate
                      e={e}
                      reload={reload}
                      setReload={setReload}
                      notify={notify}
                      decision={'btn'}
                    />
                    {e.partialPayment === true ? (
                      <button
                        className='btn btn-primary'
                        onClick={async () => {
                          let amount = await calculatePartialAmount(e);
                          setPartialAmounts(amount);
                        }}
                      >
                        Calculate Partial Amount
                      </button>
                    ) : null}

                    <SubscrionStageChange data={e} />

                    {(loginName === 'Udit' ||
                      loginName === 'BHAVYA' ||
                      loginName === 'Konark' ||
                      loginName === 'Akhil' ||
                      loginName === 'Heena') &&
                    e.dropped === false ? (
                      <ChangeBothTeleCaller data={e} />
                    ) : null}
                    {(e.orderType === orderTyepes[0].value ||
                      orderTyepes[4].value === e.orderType ||
                      orderTyepes[2].value === e.orderType) && (
                      <ReInstallation
                        sub={e}
                        reload={reload}
                        setReload={setReload}
                        page={pages.search}
                      />
                    )}
                    {e.reeInstallation &&
                      userRole.includes(user_roles.SuperAdmin) && (
                        <RevokeReInstallation
                          sub={e}
                          reload={reload}
                          setReload={setReload}
                        />
                      )}
                  </Stack>
                </div>
                <div>
                  <div className='mainSubHeadingSub'>Aliste App</div>
                  <Stack
                    direction={'row'}
                    spacing={0.5}
                    flexWrap={'wrap'}
                    gap={1}
                  >
                    <HouseAccess e={e} notify={notify} />
                    {e.houseId !== '' ? (
                      <ClientHouseWiseRetention sub={e} />
                    ) : null}
                    <HouseDeviceActivateDeactivate
                      e={e}
                      reload={reload}
                      SetReload={setReload}
                      notify={notify}
                    />
                    <UpdateHouseIdDevices
                      e={e}
                      notify={notify}
                      reload={reload}
                      setReload={setReload}
                    />
                    {
                      (loginName === "Konark" || loginName=="Akhil" || loginName==="Tanu") && (<>
                        <ManualSwitchBlock data={e} decision={1} />
                        <ManualSwitchBlock data={e} decision={0} />
                      </>
                    )}

                    {e.houseId !== '' ? <ShowDevices data={e} /> : null}
                  </Stack>
                </div>
                <div>
                  <div className='mainSubHeadingSub'>Client Details</div>
                  <Stack
                    direction={'row'}
                    spacing={0.2}
                    flexWrap={'wrap'}
                    gap={1}
                  >
                    <ClientDetailsUpdate
                      update={e}
                      notify={notify}
                      loginName={loginName}
                    />
                    {e.dropped === true ? (
                      <ReteriveClient
                        e={e}
                        reload={reload}
                        setReload={setReload}
                      />
                    ) : null}
                    {userRole.includes('Super Admin') ||
                    employeeId === '1024' ||
                    employeeId === '1058' ? (
                      <Deleteanddrop
                        e={e._id}
                        data={e}
                        reloadW={reload}
                        setReload={setReload}
                        notify={notify}
                        page={'search'}
                      />
                    ) : null}
                    <GrivanceHistory data={e} notify={notify} />
                    <LastTenActionOnGrievence data={e} />
                    <ClientSubscriptionChangeHistories
                      data={e}
                      notify={notify}
                    />
                    <ClientJoureny e={e} />
                    <SendOverDueLinkWhatapp sub={e} />
                    <LedgerForGeneratedHex
                      generatedHex={requestData.generatedHex}
                    />
                    <InvoiceDetails
                      e={e}
                      notify={notify}
                      invoice={invoice}
                      setInvoice={setInvoice}
                    />
                    <CreditNote sub={e} setCreditNotes={setCreditNotes} />
                    {loginName === 'Akhil' && (
                      <FittingStarted sub={e} distanceLog={false} />
                    )}
                  </Stack>
                </div>
              </div>
              {invoice.length !== 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {invoice.map((inv) => {
                    return (
                      <CardInvoice
                        inv={inv}
                        sub={e}
                        notify={notify}
                        setInvoice={setInvoice}
                      />
                    );
                  })}
                </div>
              ) : null}
              {creditNotes.length !== 0 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '10px',
                    width: '100%',
                  }}
                >
                  {creditNotes.map((note) => {
                    return <CreditNoteDetail note={note} />;
                  })}
                </div>
              ) : null}
            </div>
          </CardContent>
        </Collapse>
      </Card>
      <UpgradeDowngradeUi
        pop={pop}
        setPop={setPop}
        data={e}
        setReload={setReload}
        reload={reload}
      />
      <PartialUpgradeDownGrade
        setPopPartial={setPopPartial}
        popPartial={popPartial}
        data={e}
        notify={notify}
        setReload={setReload}
        reload={reload}
      />
      {requestPop === true ? (
        <form onSubmit={RequesthandleSubmit}>
          <div
            className='greivance-container'
            style={{ top: '21% !important' }}
          >
            <span className='cross' onClick={() => setRequestPop(!requestPop)}>
              x
            </span>
            <label className='create-label1'>HexCode</label>
            <div class='input-group mb-1'>
              <input
                type='text'
                defaultValue={requestData.generatedHex}
                id='hex'
                name='hex'
                autoComplete='hex'
              />
            </div>
            <label className='create-label1'>Department</label>
            <div class='input-group mb-1'>
              <select
                type='text'
                defaultValue='Sales'
                id='department'
                name='department'
                //   className="custom-select height-select"
                required
                //   style={{boxShadow: "0px -25px 20px -20px rgba(0, 0, 0, 0.45), 25px 0 20px -20px rgba(0, 0, 0, 0.45), 0px 25px 20px -20px rgba(0, 0, 0, 0.45), -25px 0 20px -20px rgba(0, 0, 0, 0.45)",width:"100%",borderRadius:"12px",fontSize:"18px"}}
                autoComplete='current-department'
              >
                {department.map((e, i) => {
                  return (
                    <option value={e.value} key={i}>
                      {e.value}
                    </option>
                  );
                })}
              </select>
            </div>
            <label className='create-label1'>Priority</label>
            <div class='input-group mb-1'>
              <input
                type='text'
                defaultValue={0}
                id='priority'
                name='priority'
                autoComplete='current-priority'
              />
            </div>
            <label className='create-label1'>
              Comment <span style={{ color: 'red' }}>*</span>
            </label>
            <div class='input-group mb-1'>
              <input
                type='text'
                required
                id='comment'
                name='comment'
                autoComplete='current-comment'
              />
            </div>

            <button className='btn btn-primary' type='submit'>
              Request
            </button>
          </div>
        </form>
      ) : null}
      {meetingPop === true ? (
        <div className='greivance-container' style={{ top: '21% !important' }}>
          <span className='cross' onClick={() => setMeetingPop(!meetingPop)}>
            x
          </span>
          <label className='create-label1'>Meeting Date and Time</label>
          <div class='input-group mb-1'>
            <input type='datetime-local' ref={meetDate} />
          </div>
          <label className='create-label1'>Meeting Comments</label>
          <div class='input-group mb-1'>
            <input type='text' ref={meetingComment} defaultValue='no' />
          </div>
          <label className='create-label1'>Meeting Status</label>
          <div class='input-group mb-1'>
            <input type='text' ref={meetingStatu} defaultValue='' />
          </div>
          <button
            className='btn btn-primary'
            onClick={() =>
              meetingSchedule(
                meetDate.current.value,
                meetingComment.current.value,
                meetingStatu.current.value
              )
            }
          >
            Meeting Schedule
          </button>
        </div>
      ) : null}

      <ToastContainer />
    </>
  );
}
