import { useEffect, useState } from 'react';
import { apiRequest } from '../../utils';
import { serverUrl } from '../ApiUrl';
import { notifyFailed } from '../Common/Notification';
import { Box, Button, Modal, TextField } from '@mui/material';

export default function LedgerForGeneratedHex({ generatedHex }) {
  const [data, setData] = useState([]);
  const [firstDate, setFirstDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [open, setOpen] = useState(false);

  console.log('[generatedHex]', generatedHex);
  const fetchLedger = async () => {
    if (firstDate === '' || lastDate === '') {
      notifyFailed('Please enter the dates!!');
      return;
    }
    const response = await apiRequest({
      url: `${serverUrl.sub}/invoice/ledger`,
      method: 'POST',
      data: {
        firstDate,
        lastDate,
        generatedHex,
      },
    });

    if (!response?.success) {
      notifyFailed(response?.message || 'Failed to fetch ledger!!');

      return;
    }

    setData(response?.data);
  };
  return (
    <div>
      <Button variant='contained' onClick={() => setOpen(true)}>
        Ledger
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <div style={{ display: 'flex', gap: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
              <label>First Date:</label>
              <TextField
                type='date'
                value={firstDate}
                onChange={(e) => setFirstDate(e.target.value)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
              <label>Last Date:</label>
              <TextField
                type='date'
                value={lastDate}
                onChange={(e) => setLastDate(e.target.value)}
              />
            </div>

            <Button variant='contained' onClick={fetchLedger}>
              Get Ledger
            </Button>
          </div>
          <p id='modal-modal-title' variant='h6' component='h2'>
            Text in a modal
          </p>
          <p id='modal-modal-description' sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p>
        </Box>
      </Modal>
    </div>
  );
}
