import React, { useState } from "react";
import { Button } from "@mui/material";
import ListPerforma from "./ListPerforma";
import CreatePerforma from "./CreatePerforma";

export default function PerformaInvoice() {
    // STATES
    const [tab, setTab] = useState("performa");
    const [performaData, setPerformaData] = useState({});
    return (
        <div>
            <div
                className="mt-3"
                style={{
                    display: "flex",
                    gap: "20px",
                    padding: "10px",
                    height: "100%",
                }}
            >
                <Button
                    color={tab === "performa" ? "primary" : "info"}
                    variant={tab === "performa" ? "contained" : "outlineds"}
                    onClick={() => setTab("performa")}
                >
                    User Proforma
                </Button>
                <Button
                    color={tab === "performa" ? "primary" : "info"}
                    variant={
                        tab === "createPerforma" ? "contained" : "outlined"
                    }
                    onClick={() => setTab("createPerforma")}
                >
                    Create Proforma
                </Button>
            </div>

            <div style={{ margin: "30px", height: "100%" }}>
                {tab === "performa" ? (
                    <ListPerforma
                        setPerformaData={setPerformaData}
                        setTab={setTab}
                    />
                ) : null}
                {tab === "createPerforma" ? (
                    <CreatePerforma
                        setTab={setTab}
                        currentTab={tab}
                        data={performaData}
                    />
                ) : null}
            </div>
        </div>
    );
}
