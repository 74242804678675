import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import {
  getAllDates,
  recursiveAmountCalculate,
} from "../Common/applianceDifference";
import { subHistory } from "../Function/SubscriptionHistory";
import store from "../Redux";
import { Elevator } from "@mui/icons-material";
import { clientData } from "./data";
import { orderTyepes, typeOFClient } from "../Data";

export const leadStatusChange = (detail) => {
  axios
    .post(`${serverUrl.amazon}/leads/statusUpdate`, detail)
    .then(function (res) {})
    .catch(function (params) {});
};

export const leadCreatedOrNot = async (number) => {
  return await axios
    .post(`${serverUrl.amazon}/leads/details`, { number: number })
    .then(function (res) {
      if (res.data.success === true) {
        if (Object.keys(res.data.data).length !== 0) {
          return res.data.data.lead;
        } else {
          return {};
        }
      } else {
        notifyFailed("Lead not Registered");
        console.log(res.data);
        return {};
      }
    })
    .catch(function (err) {
      notifyFailed("Lead Register Check Failed");
      return {};
    });
};

const downloadFile = (blob, fileName, setQutoBtnText) => {
  const link = document.createElement("a");
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  setQutoBtnText("Download");
  // in case the Blob uses a lot of memory
  // setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
const quotationApi = async (data, userData, setQutoBtnText, install) => {
  let name = `${userData.first_name} ${userData.last_name}`;
  let seq = Number(userData.security);
  let ins = Number(userData.installion);
  let qu = Number(userData.totalAppliances);
  let planNamess = install.planName;
  let planDurations = userData.planType;
  let fee = 0;
  let costAppliances = userData.costAppliances;
  let installationApply =
    userData.installationApply !== undefined
      ? userData.installationApply
      : true;
  let termsAndCons =
    userData.termsAndCons !== undefined
      ? userData.termsAndCons
      : clientData.termCondition.subscription;
  if (planDurations === "One-Time") {
    fee = Number(userData.orignalRecursiveAmount);
  } else {
    let havvyLight = qu;
    if (userData.heavyAppliances !== undefined) {
      havvyLight = qu - userData.heavyAppliances + userData.heavyAppliances * 2;
    }
    fee = await recursiveAmountCalculate(planDurations, havvyLight);
  }
  let total = fee + seq + ins;
  let tax = (fee + ins) * 0.18 - userData.insDiscount * 0.18;
  let totalTax = total + tax - userData.discount;
  let securityRate = userData?.page === "quto" ? userData.securityRate : 100;

  await axios
    .post(
      `${serverUrl.s}/misc/getQuotation`,
      {
        quoteTo: name,
        quoteNo: data,
        phone: userData.phone,
        installationApply: installationApply,
        itemsQuantity: {
          totalAppliances: qu,
          lightAppliances:
            userData.lightAppliances !== undefined
              ? userData.lightAppliances
              : qu,
          heavyAppliances:
            userData.heavyAppliances !== undefined
              ? userData.heavyAppliances
              : 0,
        },
        packagePlanDetails: {
          packageName: planNamess === undefined ? "1 Room" : planNamess,
          planName: planDurations,
          security: seq,
          installation: ins,
          fee: fee,
          total: total,
          perApplianceCost: costAppliances,
          rate: securityRate,
          lightFee: userData.lightFee !== undefined ? userData.lightFee : fee,
          heavyFee: userData.heavyFee !== undefined ? userData.heavyFee : 0,
        },
        amounts: {
          subtotal: total,
          tax: tax,
          total: totalTax,
          discount: userData.discount,
        },
        termsAndCons: termsAndCons,
      },
      {
        responseType: "blob",
        headers: {
          AccessToken: store.getState().sar.data?.token,
        },
      }
    )
    .then(function (response) {
      downloadFile(
        new Blob([response.data]),
        `Quote - ${name}.pdf`,
        setQutoBtnText
      );
      if (userData?.page === "quto") {
        let detail = {
          number: userData.phone,
          city: userData.city,
          first_name: name,
          last_name: "",
          status: "Quote Send",
          note: "",
        };
        leadStatusChange(detail);
      }
    })
    .catch(function (error) {
      console.log("quto   ", error);
    });
};
export const newClientRegister = (
  userData,
  setQutoBtnText,
  planN,
  stateN,
  userRole,
  teleCallers,
  dummy,
  setText,
  setPaymentLink,
  install,
  setOpen,
  typeOfClient = ""
) => {
  setQutoBtnText("Processing...");
  let insDiscount = 0;
  let seqDiscount = 0;
  if (userData.installion >= userData.discount) {
    insDiscount = userData.discount;
  } else {
    if (userData.discount > userData.installion) {
      insDiscount = userData.installion;
      seqDiscount = userData.discount - userData.installion;
    }
  }

  let data1 = async () => {
    await axios
      .post(`${serverUrl.u}/api/zoho/registerLeadAndLink`, {
        planId: planN,
        planFrequency: userData.planType,
        refferal: userData.refferal !== "" ? userData.refferal : "empty",
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone: userData.phone,
        city: stateN.toUpperCase(),
        zip: userData.zip,
        address: userData.address,
        teleCallerId: userRole.includes("External Telecaller")
          ? teleCallers
          : userData.teleCallerId,
        comments: userData.comments,
        leadQuality: userData.leadQuality,
        origin: userData.origin,
        layout: dummy[planN],
        security: userData.security - seqDiscount,
        installation: userData.installion - insDiscount,
        partialPayment: true,
        secondarySalesPerson: userData.teleCallerSecond,
        discount: {
          applied: Number(userData.discount) > 0 ? true : false,
          amount: Number(userData.discount),
          reason: "crm discount",
        },
        orignalRecursiveAmount: userData.orignalRecursiveAmount,
        orignalTotalAmount: Number(userData.orignalTotalAmount),
        recursiveAmount: userData.recursiveAmount,
        totalAmount: Number(
          (
            Number(userData.orignalTotalAmount) -
            userData.discount -
            Number((insDiscount * 0.18).toFixed(2))
          ).toFixed(2)
        ),
        addOnAmount: 0,
        totalAppliances: userData.totalAppliances,
        addOnSubscriptionTo: "empty",
        partialAmount: Number(userData.partialAmount),
        paymentMode: userData.paymentMode,
        reference_id: userData.reference_id,
        zone: userData.incentiveZone,
        InstallationDate: userData.InstallationDate,
        paymentLinkSend: userData.page === undefined ? true : false,
        orderType: typeOfClient === "" ? typeOFClient[0].value : typeOfClient,
      })
      .then(async function (resp) {
        console.log(resp);
        if (resp.data.success === true) {
          if (userData.InstallationDate !== "") {
            subHistory(
              "Installation date set first time",
              resp.data.data,
              "Intallation date Change",
              userData.InstallationDate
            );
          }
          subHistory(
            "Client Register",
            resp.data.data,
            "Client Register",
            "New Client",
            typeOfClient
          );

          console.log("Type of Client - ", typeOfClient);
          if (typeOfClient !== "smartMeter-Visit") {
            console.log("BEING CALLED");
            await quotationApi(
              resp.data.data.quotationNumber,
              { ...userData, insDiscount },
              setQutoBtnText,
              install
            );
          } else {
            setQutoBtnText("Download");
          }
          if (Number(userData.partialAmount) !== 0) {
            setText(`Hey ${userData.first_name},
                     Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${userData.partialAmount} on the following link :  ${resp.data.data.partialPaymentDetails.short_url}`);
            navigator.clipboard.writeText(
              resp.data.data.partialPaymentDetails.short_url
            );
            setPaymentLink(resp.data.data.partialPaymentDetails.short_url);
            if (userData.page === undefined) {
              let detail = {
                number: userData.phone,
                city: userData.city,
                first_name: userData.first_name,
                last_name: userData.last_name,
                status: "Link Send",
                note: "",
              };
              leadStatusChange(detail);
            }
          }

          setOpen(true);
        } else {
          console.log(resp.data.message);
          setQutoBtnText("Download");
          notifyFailed(resp.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
        notifyFailed("Failed New Client Register");
      });
  };
  if (planN === 0) {
    dummy[planN][0].switchboards[0].appliances.fans = Number(
      userData.totalAppliances
    );
    data1();
  } else {
    data1();
  }
};

export const raiseRequestToRegisterOfflineClient = (
  userData,
  setQutoBtnText,
  planN,
  dummy,
  setText,
  setPaymentLink
) => {
  setQutoBtnText("Processing...");
  let data1 = async () => {
    await axios
      .post(`${serverUrl.u}/api/tele/raiseRequestToRegisterOfflineClient`, {
        planId: planN,
        planFrequency: userData.planType,
        refferal: userData.refferal !== "" ? userData.refferal : "empty",
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone: userData.phone,
        city: userData.city.toUpperCase(),
        zip: userData.zip,
        address: userData.address,
        teleCallerId: userData.teleCallerId,
        comments: userData.comments,
        leadQuality: userData.leadQuality,
        origin: userData.origin,
        layout: dummy[planN],
        security: userData.security,
        installation: userData.installion,
        partialPayment: false,
        secondarySalesPerson: userData.teleCallerSecond,
        discount: 0,
        orignalRecursiveAmount: userData.orignalRecursiveAmount,
        orignalTotalAmount: Number(userData.orignalTotalAmount),
        recursiveAmount: userData.recursiveAmount,
        totalAmount: Number(userData.orignalTotalAmount),
        addOnAmount: 0,
        totalAppliances: userData.totalAppliances,
        addOnSubscriptionTo: "empty",
        partialAmount: 0,
        paymentMode: userData.paymentMode,
        reference_id: userData.reference_id,
        mandateDate: userData.mandateDate,
        start_date: userData.startDate,
        createdAt: userData.createdAt,
        oldHex: userData.oldHex,
        IsB2BSubscription: false,
        B2BRoom_No: "empty",
        B2BCompany_Name: "empty",
        B2BPropertyId: "empty",
      })
      .then(function (resp) {
        if (resp.status === 200) {
          setQutoBtnText("Done");

          setText(`Hey ${userData.first_name},
            Book your Aliste Smart Home in a few clicks by carrying out a payment of INR ${userData.orignalTotalAmount} on the following link :  ${resp.data.url}`);
          navigator.clipboard.writeText(resp.data.url);
          setPaymentLink(resp.data.url);

          notify("Sucess");
          //  requests(resp.data.subscriptionsData.generatedHex);
        } else {
          notify("Error Occured");
          setQutoBtnText("Failed");
        }
      })
      .catch(function (error) {
        notify("Error Occured1");
        setQutoBtnText("Failed");
      });
  };
  if (planN === 0) {
    dummy[planN][0].switchboards[0].appliances.fans = Number(
      userData.totalAppliances
    );
    data1();
  } else {
    data1();
  }
};

export const B2BClientRegister = (
  userData,
  setQutoBtnText,
  selectedProperties,
  selectedCompany,
  install
) => {
  setQutoBtnText("Processing...");
  let detail = {
    planId: selectedProperties.planId,
    planFrequency: selectedProperties.planFrequency,
    refferal: "empty",
    email: selectedCompany.email,
    first_name: selectedCompany.companyName,
    last_name: "",
    phone: selectedCompany.phone,
    city: selectedProperties.city.toUpperCase(),
    zip: "",
    teleCallerId: userData.teleCallerId,
    comments: "",
    leadQuality: 0,
    origin: "website",
    layout: [
      {
        roomName: "Custom",
        roomId: 0,
        switchboards: [
          {
            switchboardNumber: "1",
            appliances: {
              fans: 0,
              light: 0,
              ac: 0,
              geyser: 0,
              tv: 0,
              socket: 0,
              other: Number(userData.quantityAppliances),
              ir: 0,
            },
          },
        ],
      },
    ],
    security: install.security,
    installation: install.Installion,
    partialPayment: false,
    secondarySalesPerson: userData.teleCallerSecond,
    discount: 0,
    orignalRecursiveAmount: userData.orignalRecursiveAmount,
    orignalTotalAmount: Number(userData.orignalTotalAmount),
    recursiveAmount: userData.recursiveAmount,
    totalAmount: Number(userData.orignalTotalAmount),
    addOnAmount: 0,
    totalAppliances: Number(userData.quantityAppliances),
    addOnSubscriptionTo: "empty",
    partialAmount: 0,
    paymentMode: userData.paymentMode,
    reference_id: userData.reference_id,
    mandateDate: userData.mandateDate,
    start_date: userData.mandateDate,
    createdAt: userData.mandateDate,
    oldHex: userData.oldHex,
    IsB2BSubscription: true,
    B2BRoom_No: userData.room_no,
    B2BCompany_Name: selectedCompany.companyName,
    B2BPropertyId: selectedProperties._id,
    address: selectedProperties.address,
    gstNumber: selectedCompany.gst,
    gstName: selectedCompany.companyName,
    B2BAddress: selectedCompany.address,
  };

  let data1 = async () => {
    try {
      const resp = await axios.post(
        `${serverUrl.u}/api/tele/raiseRequestToRegisterOfflineClient`,
        detail
      );

      if (resp.status === 200) {
        setQutoBtnText("Done");
        notify("Sucess");
      } else {
        notify("Error Occured");
        setQutoBtnText("Failed");
      }
    } catch (error) {
      notify("Error Occured");
      setQutoBtnText("Failed");
    }
  };
  data1();
};

export const capex = (
  userData,
  stateN,
  setText,
  setPaymentLink,
  setQutoBtnText,
  setWhatappNumber,
  install,
  setOpen
) => {
  let detail = {
    upsellItems: [],
    perApplianceCost: Number(userData.costPerAppliances),
    orderOrigin: "CRM",
    totalAppliances: Number(userData.totalAppliances),
    recursiveAmount: Number(userData.recursiveAmount),
    totalAmount: Number(userData.orignalTotalAmount),
    secondarySalesPerson: userData.teleCallerSecond,
    partialPayment: true,
    partialAmount: Number(userData.partialAmount),
    refferal: "empty",
    installation: Number(userData.installion),
    teleCallerId: userData.teleCallerId,
    comments: userData.comments,
    zip: userData.zip,
    address: userData.address,
    zone: userData.incentiveZone,
    city: stateN.toUpperCase(),
    phone: userData.phone,
    email: userData.email,
    first_name: userData.first_name,
    last_name: userData.last_name,
    orderType: "capex",
    InstallationDate: userData.InstallationDate,
  };
  axios
    .post(`${serverUrl.u}/api/zoho/registerLeadAndLinkUpsell`, detail)
    .then(function (res) {
      if (res.data.success === true) {
        notify(res.data.message);
        setQutoBtnText("Done");
        if (Number(userData.partialAmount) !== 0) {
          setWhatappNumber(userData.phone);
          setText(`${res.data.data.partialPaymentDetails.short_url}`);
          navigator.clipboard.writeText(
            res.data.data.partialPaymentDetails.short_url
          );
          setPaymentLink(res.data.data.partialPaymentDetails.short_url);
          subHistory(
            "Capex Created",
            res.data.data,
            "Upsell Created",
            res.data.data.partialPaymentDetails.short_url
          );
          setOpen(true);
        } else {
          subHistory(
            "Capex Created",
            res.data.data,
            "Upsell Created",
            "Zero payment"
          );
        }
      } else {
        notify(res.data.message);
        setQutoBtnText("Failed");
      }
    })
    .catch(function (err) {
      console.log(err);
      notify(err.response.data.message);
      setQutoBtnText("Failed");
    });
};

export const getSlots = async (zone) => {
  let time = {
    startDate: new Date(),
    endDate: new Date().setDate(new Date().getDate() + 7),
  };
  let dates = getAllDates(time);
  let InstallationDateArray = [];
  for (let d of dates) {
    InstallationDateArray.push(d.date);
  }

  let finalDates = [];
  await axios
    .post(`${serverUrl.u}/api/tele/checkAvailableSlot`, {
      InstallationDateArray: InstallationDateArray,
      zone: zone,
    })
    .then(function (res) {
      if (res.data.success) {
        if (res.data.data.success) {
          for (let i of res.data.data.availableSlot) {
            let d = Object.keys(i);
            let obj = {
              date: d[0],
              slotStatus: i[d[0]].slot,
              count: i[d[0]].availableSlot,
            };
            finalDates.push(obj);
            console.log(obj);
          }
        }
      } else {
        notifyFailed(res.data.message);
      }
    })
    .catch(function (err) {
      console.log(err);
      notifyFailed(err.response.data.message);
    });

  return finalDates;
};

export const registerB2BSubscription = async (propertySub, setQutoBtnText) => {
  await axios
    .post(`${serverUrl.u}/api/B2B/registerB2BSubscriptions`, {
      email: propertySub.email,
      CompanyName: propertySub.companyName,
      phone: propertySub.phone,
      city: propertySub.city,
      zone: propertySub.zone,
      quantity: propertySub.totalAppliances,
      billing_address: propertySub.billing_address,
      propertyName: propertySub.propertyName,
      Address: propertySub.propertyAddress,
      teleCallerId: propertySub.teleCallerId,
      B2BPropertyId: propertySub.B2BPropertyId,
      gstName: propertySub.gstName,
      gstNumber: propertySub.gstNumber,
      planFrequency: propertySub.planFrequency,
      currentSecurity: propertySub.currentSecurity,
      currentInstallation: propertySub.currentInstallation,
      currentRecursive: propertySub.currentRecursive,
      currentTotal: propertySub.currentTotal,
    })
    .then(function (res) {
      if (res.data.success) {
        notify(res.data.message);
        setQutoBtnText("Submit");
      } else {
        notifyFailed(res.data.message);
        setQutoBtnText("Submit");
      }
    })
    .catch(function (err) {
      notifyFailed("Subscription Register Failed");
      setQutoBtnText("Submit");
    });
};

export const addProperty = async (property, setQutoBtnText) => {
  await axios
    .post(`${serverUrl.u}/api/prp/addProperty`, {
      propertyName: property.propertyName,
      companyCode: property.companyCode,
      address: property.propertyAddress,
      planFrequency: property.planFrequency,
      totalAppliances: property.totalAppliances,
      planId: 0,
      houseId: property.houseId,
      city: property.city,
      planCode: property.planCode,
    })
    .then(async function (propertyRes) {
      if (propertyRes.data.message === "property create") {
        notify(propertyRes.data.message);
        let propertyId = propertyRes.data.propertiesRecord._id;
        property = { ...property, B2BPropertyId: propertyId };
        await registerB2BSubscription(property, setQutoBtnText);
      } else {
        notifyFailed(propertyRes.data.message);
        setQutoBtnText("Submit");
      }
    })
    .catch(function (err) {
      notifyFailed("Property Creating Failed");
      setQutoBtnText("Submit");
    });
};

export const registerHouseWithOutSubscription = async (
  house,
  setQutoBtnText
) => {
  await axios
    .post(`${serverUrl.u}/api/sub/createHouseWithoutSubscription`, {
      masters: house.masters,
      houseName: house.houseName,
    })
    .then(async function (houseRes) {
      if (houseRes.data.success) {
        notify(houseRes.data.message);
        if (houseRes.data.data.success) {
          house = { ...house, houseId: houseRes.data.data.houseId };
          await addProperty(house, setQutoBtnText);
        } else {
          notifyFailed("House Not Created");
          setQutoBtnText("Submit");
        }
      } else {
        notifyFailed("House Not Created");
        setQutoBtnText("Submit");
      }
    })
    .catch(function (err) {
      notifyFailed("House Creation Failed");
      setQutoBtnText("Submit");
    });
};

export const smartMeterClientRegister = async (data) => {
  await axios
    .post(`${serverUrl.smartMeter}/client/register`, data)
    .then((res) => {
      if (res.data.success) {
        notify(res.data.message);
      } else {
        notifyFailed(res.data.message);
      }
    })
    .catch((err) => {
      console.log(err);
      notifyFailed(err?.response?.data?.message);
    });
};

export const registerNewClient = async (data) => {
  await axios
    .post(`${serverUrl.u}/v3/customer/create`, data)
    .then((res) => {
      if (res.data.success) {
        notify("User Created");
        // return {success: true}
      } else {
        notifyFailed(res.data.message);
        // return {success: false}
      }
    })
    .catch((error) => {
      notifyFailed(
        error?.response?.data?.data?.error ||
          error?.response?.data?.data?.message ||
          "Something Went Wrong!!"
      );
      // return {success: false}
    });
};

// export const searchClient = async (data) => {
//   await axios
//     .get(`${serverUrl.test}/v3/customer/search?search=${data.query}`)
//     .then((res) => {
//       console.log(res.data);
//       if (res.data.success) {
//         console.log("here - ", res.data.success);
//         return {
//           success: true,
//           data: res.data.data,
//         };
//       } else {
//         return {
//           success: false,
//           error: "Something went wrong",
//         };
//       }
//     })
//     .catch((error) => {
//       console.log(error, " - ERROR");
//       return {
//         success: false,
//         error: error?.response?.data?.message || "Something Went Wrong!!",
//       };
//     });
// };

export const searchClient = async (data) => {
  try {
    const response = await axios.get(
      `${serverUrl.u}/v3/customer/search?params=${data}`
    );

    if (response.data.success) {
      return {
        success: true,
        data: response.data.data,
      };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response.data?.message,
    };
  }
};

export const fetchAllItems = async () => {
  try {
    const response = await axios.get(`${serverUrl.u}/v3/items/fetch`);

    if (response.data.success) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};
