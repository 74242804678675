import React, { useEffect, useState, useRef } from "react";

import { apiRequest } from "../../../utils";
import { serverUrl } from "../../../component/ApiUrl";
import { notify, notifyFailed } from "../../../component/Common/Notification";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ReactSelect from "react-select";
import { typeOfLocaionLogs } from "../../../component/SpecificElectricianUpGradeDownGrade.js/api";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Download } from "@mui/icons-material";

import "../styles/listInvoices.scss";

export default function ListInvoices() {
  // STATE
  const [invoiceTypes, setInvoiceTypes] = useState([]);
  const [invoiceType, setInvoiceType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [loading, setLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch Invoices
  async function fetchInvoices() {
    const resposne = await apiRequest({
      url: `${serverUrl.web}/v3/invoices/report`,
      data: {
        invoiceType,
        startDate,
        endDate,
      },
      method: "POST",
    });

    if (!resposne.success) {
      notifyFailed(resposne.message);
      return;
    }

    setInvoices(resposne.data?.data?.invoices);
    notify("Invoices fetched successfully :)");
  }

  // Fetch Customer
  async function fetchCustomerData(query) {
    const response = await apiRequest({
      url: `${serverUrl.u}/v3/customer/search?params=${query}`,
      method: "GET",
    });

    if (!response.success) {
      notifyFailed(
        `Search customer failed ${response?.message && response?.message}}`
      );

      setIsDropdownVisible(false);

      return;
    }

    setCustomerData(response.data?.data?.customers);
    setIsDropdownVisible(true);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (startDate === "") {
      notifyFailed("Please select the Start Date.");
      return;
    }
    if (endDate === "") {
      notifyFailed("Please select the End Date.");
      return;
    }
    if (invoiceType === "") {
      notifyFailed("Please select the Invoice Type");
      return;
    }

    fetchInvoices();
  }

  // Fetch Invoice Types
  useEffect(() => {
    const fetchInvoiceTypes = async () => {
      const response = await apiRequest({
        url: `${serverUrl.u}/v3/invoiceSeries/list`,
        method: "GET",
      });

      if (!response.success) {
        notifyFailed(`Failed to load Invoice Type data.\n${response.message}`);
        return;
      }

      setInvoiceTypes(response?.data?.data?.invoiceSeries);
    };

    fetchInvoiceTypes();
  }, []);

  useEffect(() => {
    if (searchQuery.length < 3) return;

    const fetchData = async () => {
      await fetchCustomerData(searchQuery);
    };

    fetchData();
  }, [searchQuery]);

  //  INVOICES
  async function handleInvoiceDownload(data) {
    notify("Generating Invoice...");
    const response = await apiRequest({
      url: `${serverUrl.u}/v3/invoices/pdf`,
      data: {
        invoiceId: data.InvoiceId,
      },
      method: "POST",
      responseType: "blob",
    });
    downloadFile(
      new Blob([response.data], { type: "application/pdf" }),
      `Invoice-${data.Number}.pdf`
    );
  }

  async function handleFetchByInvoiceId(e) {
    e.preventDefault();
    setLoading(true);
    const response = await apiRequest({
      url: `${serverUrl.u}/v3/invoices/byNumber`,
      data: {
        invoiceNumber: invoiceId,
      },
      method: "POST",
    });

    console.log("INVOICE RESPOSE -", response);
    if (!response.success) {
      notifyFailed(response.message);
      setLoading(false);
      return;
    }

    setInvoices(response?.data?.data?.invoices);
    setLoading(false);
  }

  async function handleInvoiceByCustomer(cus) {
    const response = await apiRequest({
      url: `${serverUrl.u}/v3/invoices/byCustomer`,
      data: { customerId: cus._id },
      method: "POST",
    });

    if (!response.success) {
      notifyFailed(response.message);
      setIsDropdownVisible(false);
      return;
    }

    notify(`Successfully fetched the invoices of ${cus.first_name}`);
    setIsDropdownVisible(false);
    setInvoices(response.data?.data?.invoices);
  }

  const searchCustomerDropdownRef = useRef(null);

  const columns =
    invoices.length > 0
      ? [
          ...Object.keys(invoices[0]).map((header) => ({
            field: header,
          })),
          {
            field: "actions",
            headerName: "Actions",
            renderCell: (params) => (
              <Button
                variant="container"
                color="primary"
                size="small"
                onClick={() => handleInvoiceDownload(params.row)}
              >
                <Download />
              </Button>
            ),
          },
        ]
      : [];

  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  };
  return (
    <div className="listInvoices">
      <h2 className="mt-4">Invoices</h2>

      <Paper elevation={1} sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Stack direction={"row"} spacing={2} sx={{ alignItems: "flex-end" }}>
            <ReactSelect
              options={invoiceTypes.map((type) => ({
                label: type.name,
                value: type._id,
              }))}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "250px",
                }),
              }}
              value={invoiceTypes
                .map((type) => ({
                  label: type.name,
                  value: type._id,
                }))
                .find((option) => option.value === invoiceType)}
              placeholder="Invoice Type..."
              onChange={(selected) => setInvoiceType(selected.value)}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Start Date</label>
              <input
                type="date"
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>End Date:</label>
              <input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                required
                // defaultValue={}
              />
            </div>

            <Button variant="contained" size="small" type="submit">
              Fetch Invoices
            </Button>
          </Stack>
        </form>

        <Divider sx={{ mt: 5 }} />
        <Box sx={{ mt: 5 }}>
          <form onSubmit={handleFetchByInvoiceId}>
            <Stack direction="row" spacing={2}>
              <TextField
                label="Invoice Id"
                value={invoiceId}
                onChange={(e) => setInvoiceId(e.target.value)}
              />
              <Button type="submit" size="small" variant="contained">
                Fetch Invoices
              </Button>
            </Stack>
          </form>
        </Box>

        <Divider sx={{ mt: 5 }} />
        <Box sx={{ mt: 5 }}>
          <h4>Invoices By Customer</h4>
          <div className="search-customer-container">
            <input
              type="text"
              value={searchQuery}
              placeholder="Search Customer"
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
            {isDropdownVisible && customerData.length > 0 && (
              <div className="search-customer-result">
                {customerData.length > 0 &&
                  customerData.map((cus) => {
                    return (
                      <div
                        key={`${cus.first_name}${cus.last_name}`}
                        onClick={() => handleInvoiceByCustomer(cus)}
                        className="customer-container"
                        ref={searchCustomerDropdownRef}
                      >
                        <p>
                          {cus.first_name} {cus.last_name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </Box>
      </Paper>

      <div style={{ marginTop: "40px" }}>
        {invoices.length > 0 && (
          <DataGrid
            columns={columns}
            rows={invoices.map((inv, index) => {
              return {
                id: index,
                ...inv,
              };
            })}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: {
                  fileName: `LeadQuality_referrals`,
                },
              },
            }}
            pageSizeOptions={[10, 20, 30, 40]}
          />
        )}
      </div>
    </div>
  );
}
